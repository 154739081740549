<template>
  <div style="height: 100%">
    <!--【版头】标题 -->
    <nesi-user-common :title="`预约管理`" v-if="isShowModify == false">
      <template #button>
        <el-button
          type="primary"
          autofocus="autofocus1"
          plain
          @click="setAddTableRowForm(1)"
          >预约房源</el-button
        >
        <el-button
          type="primary"
          autofocus="autofocus2"
          plain
          @click="setAddTableRowForm(2)"
          >预约合同</el-button
        >
      </template>
      <!--【版头】查询条件 -->
      <!--      <template #query>-->
      <!--        <el-form :inline="true" :model="form" class="demo-form-inline">-->
      <!--          <el-form-item label="审核状态">-->
      <!--            <el-select v-model="form.status" clearable placeholder="请选择审核状态">-->
      <!--              <el-option label="待审核" value="0"/>-->
      <!--              <el-option label="审核不通过" value="1"/>-->
      <!--              <el-option label="审核通过" value="2"/>-->
      <!--            </el-select>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item label="名称" style="width: 293px;">-->
      <!--            <el-input v-model="form.name" clearable placeholder="请输入名称关键字"/>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item label="地址" style="width: 293px;">-->
      <!--            <el-input v-model="form.address" clearable placeholder="请输入地址关键字"/>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <el-button type="primary" @click="onSubmit">查询</el-button>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </template>-->
      <!--【主区】列表 -->
      <template #content>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
        >
          <el-table-column prop="house_location" label="房源详情" />
          <el-table-column prop="publishName" label="联系人" width="100" />
          <el-table-column prop="kfrs" label="看房人数" width="80" />
          <el-table-column prop="yysj" label="预约时间" width="160" />
          <el-table-column prop="status" label="预约状态" width="130" />
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                type="text"
                v-show="scope.row.statemark != 0"
                size="small"
                class="list-btn"
                @click="edit(scope.row)"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                v-show="scope.row.statemark != 0"
                class="list-btn"
                style="color: red"
                @click="del(scope.row)"
                >取消
              </el-button>
              <el-button
                type="text"
                size="small"
                v-show="scope.row.statemark != 0"
                class="list-btn"
                @click="mobile(scope.row)"
                >拨打号码
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: red"
                v-show="scope.row.statemark === 0"
                class="list-btn"
                @click="hidened(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="currentChange"
          :total="total"
          :page-size="form.pageSize"
          style="text-align: right; margin: 10px"
        />
      </template>
    </nesi-user-common>
    <!--【弹窗】新增 -->
  </div>
</template>

<script setup>
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { h } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import {
  findCommunityApply,
  addCommunityApply,
  editCommunityApply
} from '@/api/user-community'
import {
  postAppointSave,
  postAppointList,
  postAppointCancel,
  postFreeVirtualPhone,
  postAppointDel
} from '@/api/user-house'
import { ElMessage, ElMessageBox } from 'element-plus'
const router = useRouter()

defineComponent({
  NesiUserCommon
})

/**
 * 查询
 */
const total = ref(0)
let autofocus1 = ref(true)
let autofocus2 = ref(false)
let isShowModify = ref(false)
let appointType = ref(1)
const form = reactive({
  appointType: 1,
  cardNo: null,
  companyId: null,
  pageNo: 1,
  pageSize: 10,
  type: 1
})
let tableData = ref([])

const postAppointCancelAction = async (cancelAppointmentReq) => {
  const resp = await postAppointCancel(cancelAppointmentReq)
}

const findTableList = async () => {
  const resp = await postAppointList(form)
  total.value = resp.total
  tableData.value = resp.records.map((item) => {
    let status = ref('')
    if (item.statemark == '0') {
      status = '预约已过期'
    } else if (item.statemark == '1') {
      status = '预约中'
    } else {
      status = '预约中'
    }
    return {
      id: item.id,
      agentCompany: item.agentCompany,
      appointName: item.appointName,
      appointUserId: item.appointUserId,
      appointmentType: item.appointmentType,
      beginTime: item.beginTime,
      create_time: item.create_time,
      endTime: item.endTime,
      hidemark: item.hidemark,
      house_location: item.house_location,
      kfrs: item.kfrs,
      modify_time: item.modify_time,
      publicshUserId: item.publicshUserId,
      publishName: item.publishName,
      sfpj: item.sfpj,
      statemark: item.statemark,
      yysj: item.yysj,
      status: status
    }
  })
}

const edit = (row) => {
  router.push('/user/reservaapply/index')
}

const setEditTableRowForm = (row) => {}

const mobile = async (row) => {
  const resp = await postFreeVirtualPhone({
    mode: 'ax',
    userId: row.publicshUserId
  })

  ElMessageBox({
    title: '信息',
    message: h('p', null, [h('span', null, '请手机拨打联系人号码： ' + resp.data.telX)]),
    showCancelButton: true,
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true
        instance.confirmButtonText = 'Loading...'
      } else {
        done()
      }
    }
  }).then((action) => {
    ElMessage({
      type: 'info',
      message: `action: ${action}`
    })
  })
}

const del = (row) => {
  ElMessageBox.confirm('您确定要取消此预约申请吗?', '系统提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      postAppointCancelAction({
        appointType: row.appointmentType,
        appointId: row.id
      })
    })
    .catch(() => {})
}

const setAddTableRowForm = (index) => {
  appointType = index
  tableData.value = ref([])
  if (index == 1) {
    autofocus1 = true
    autofocus2 = false
    form.appointType = 1
  } else {
    autofocus2 = true
    autofocus1 = false
    form.appointType = 2
  }
  findTableList()
}

const hidened = async (row) => {
  const resp = await postAppointDel({
    appointId: row.id,
    appointType: appointType.value,
    type: row.appointmentType
  })
  findTableList()
}

onMounted(() => {
  findTableList()
})
</script>
<style>
.content {
  margin-top: 5px;
  font-size: 13px;

  p {
    padding: 3px 0;
  }
}
</style>
